import React from "react";
import Layout from "../components/Layout";
import SEO from '../components/seo';
import FormDemonstracao from '../components/Forms/FormDemonstracao';
import Footer from "../components/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const ContatoPage = () => {
  return (
    <div>
      <SEO 
          title='Demonstração da plataforma Shelfin'
          description='Acompanhe e desenvolva as COMPETÊNCIAS e HABILIDADES que seus colaboradores necessitam para potencializar os resultados de sua empresa.'
      />
      <div className="mb-20 back-demonstracao w-full min-h-screen flex justify-center">
          <div className="flex flex-col w-full lg:w-3/4 mx-2 lg:mx-0 mt-10">
            <div className="w-full mb-4">
              <img className="h-14" src="/images/logo_escura.png"/>
            </div>
            <div className="flex flex-col-reverse lg:flex-row w-full pb-10 space-y-8 lg:space-y-0 bg-white rounded-lg shadow-md">
                <div className="w-full lg:w-1/2 flex flex-col space-y-4 p-4 lg:p-10 text-gray-700">

                  <div className="text-4xl font-medium">Pronto para começar?</div>
                  <div className="text-lg mb-4">Descubra como a gestão do conhecimento de sua empresa pode alavancar os resultados de seus colaboradores!</div>
                  <div className="flex flex-col space-y-4">
                    <div className="flex flex-row space-x-2">
                        <div className="rounded-lg text-green-600 px-4 flex items-center justify-center"><FontAwesomeIcon icon={faCheck} size="2x"/></div>
                        <div className="">Faça fazer GESTÃO DO CONHECIMENTO TÉCNICO E OPERACIONAL de sua empresa.</div>
                    </div>
                    <div className="flex flex-row space-x-2">
                        <div className="rounded-lg text-green-600 px-4 flex items-center justify-center"><FontAwesomeIcon icon={faCheck} size="2x"/></div>
                        <div className="">Promova o CONHECIMENTO ON THE JOB de sua equipe.</div>
                    </div>
                    <div className="flex flex-row space-x-2">
                        <div className="rounded-lg text-green-600 px-4 flex items-center justify-center"><FontAwesomeIcon icon={faCheck} size="2x"/></div>
                        <div className="">Ofereça NOVOS APRENDIZADOS para seus colaboradores?</div>
                    </div>
                    <div className="flex flex-row space-x-2">
                        <div className="rounded-lg text-green-600 px-4 flex items-center justify-center"><FontAwesomeIcon icon={faCheck} size="2x"/></div>
                        <div className="">Saiba quais as COMPETÊNCIAS, HABILIDADES E ATITUDES seus colaboradores possuem?</div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center w-full lg:w-1/2 h-full"> 
                    <div className="w-3/4 mt-10 ">
                      <FormDemonstracao/>
                    </div>
                </div>
            </div>
          </div>
      </div>

      <div>

      </div>

      <Footer/>
    
      {/* <div className="flex w-full justify-center my-10"> 
        <div className="flex w-2/3"> 
          <div className="w-full flex flex-col lg:flex-row space-y-4 lg:space-y-0 space-x-0 lg:space-x-4">
            <div className="flex flex-col w-full lg:flex-grow">
                <div className="flex flex-col space-y-4">
                  <div className="flex flex-col space-y-3">
                    <div className="text-3xl">
                      Contato
                    </div>
                    <div className="text-lg font-light">
                      Entraremos em contato em até 24 horas.
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2">
                    <FormContato/>
                  </div>
                </div>
            </div>
            <div className="relative flex flex-col lg:flex-none p-4 space-y-4 w-full h-full bg-gray-50 rounded-md lg:w-96">
              <div class="sticky top-4">
                  <div className="flex flex-row space-x-4 items-center w-full">
                    <div className="flex flex-grow">
                      <div className="flex flex-col w-full">
                        <div className="text-lg font-semibold">
                            Envie-nos um email
                        </div>
                        <div className="flex flex-col font-normal">
                          <a>contato@shelfin.com.br</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row space-x-4 items-center w-full">
                    <div className="flex flex-grow">
                      <div className="flex flex-col w-full">
                        <div className="text-lg font-semibold">
                            Horário de atendimento
                        </div>
                        <div className="flex flex-col font-normal">
                          Atendemos de segunda a sexta das 08:00 as 19:00h
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default ContatoPage
